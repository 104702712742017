@import './shared/styles/_vuetify-overrides.scss';











































































.cassie-login-form-container {
  height: 80%;
}

.cassie-login-portal-background{
  background: var(--global-color-nav-background);
}

.cassie-login-form-logo .v-image__image {
	background-position: top !important;
	-webkit-background-size: auto;
}
